const videosYoutubeContainer = document.querySelectorAll(
  '.wp-block-embed__wrapper'
);

videosYoutubeContainer.forEach((item) => {
  item.classList.add('plyr__video-embed');
  const itempplyr = new Plyr(item, {
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'fullscreen',
    ],
  });
});
